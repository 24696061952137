import { render, staticRenderFns } from "./config-profile-list.vue?vue&type=template&id=fdacb10e&scoped=true&"
import script from "./config-profile-list.vue?vue&type=script&lang=ts&"
export * from "./config-profile-list.vue?vue&type=script&lang=ts&"
import style0 from "./config-profile-list.vue?vue&type=style&index=0&id=fdacb10e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fdacb10e",
  null
  
)

export default component.exports
import { ApiResult, PageDataApiResult, PagerQueryBean, requestRaw } from '@/libs/http-request'

/**
 * 应用版本接口
 */
export interface ApplicationProfile {

    id?: number;

    // 应用名称
    applicationName?: string;

    // 应用代码
    applicationCode?: string;

    // 项目版本
    profile?: string;

    // 配置数目
    configCount?: number;

    // 分支
    branch?: string;

    /// 说明
    memo?: string;
  }

/**
 * 应用版本请求api接口
 */
class ApplicationProfileApi {
  /**
     * 根据querybean查询数据
     * @param pagerQueryBean
     * @param baseUrl
     * @returns
     */
  public doPullDataList (pagerQueryBean: PagerQueryBean, baseUrl: string): Promise<PageDataApiResult<ApplicationProfile>> {
    return requestRaw<PageDataApiResult<ApplicationProfile>>({
      url: baseUrl + 'appProfile/queryPageList',
      data: pagerQueryBean,
      method: 'POST'
    }, false)
  }

  /**
   * 插入一条记录
   * @param dataList
   * @param baseUrl
   * @returns
   */
  public insertItem (item: ApplicationProfile, baseUrl: string) {
    return requestRaw<PageDataApiResult<ApplicationProfile>>({
      url: baseUrl + 'appProfile',
      data: item,
      method: 'PUT'
    }, false)
  }

  /**
   * 保存一条记录
   * @param item
   * @param baseUrl
   * @returns
   */
  public saveItem (item: ApplicationProfile, baseUrl: string) {
    return requestRaw<PageDataApiResult<ApplicationProfile>>({
      url: baseUrl + 'appProfile/save',
      data: item,
      method: 'POST'
    }, false)
  }

  /**
   * 根据主键列表删除数据
   * @param ids
   * @param baseUrl
   * @returns
   */
  public deleteItemByIds (ids: number[], baseUrl: string): Promise<ApiResult> {
    return requestRaw<ApiResult>({
      url: baseUrl + 'appProfile',
      data: ids,
      method: 'DELETE'
    }, false)
  }
}

export default new ApplicationProfileApi()

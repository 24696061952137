
import ExpendSearchPanel from '@/frame/share/expend-search-panel.vue'
import TablePanel from '@/frame/share/table-panel.vue'
import BaseTablePanel from '@/frame/share/table-panel'
import { PagerQueryBean, PageListData, PageDataApiResult } from '@/libs/http-request'
import _ from 'lodash'
import tableConfig from './version-list.config'
import { Component, Prop, Watch } from 'vue-property-decorator'
import ApplicationProfileApi, { ApplicationProfile } from '@/api/maintain/application-profile'
import { ConfigApplication } from '@/api/maintain/config-application'
import { getterCurConfigApplciation, updateCurAppProfile } from '@/store'

const defaultQuery = {
  page: 1,
  pageSize: 10,
  sorts: [],
  applicationCode: undefined,
  branch: undefined,
  profile: undefined
}

@Component({
  name: 'configVersionDataList',
  components: { ExpendSearchPanel, TablePanel }
})
export default class ConfigVersionDataList extends BaseTablePanel<ApplicationProfile> {
  get curApplication () {
    return getterCurConfigApplciation()
  }

  /// ip
  ip = ''
  /// 端口
  port = ''

  get baseUrl () {
    return `${location.protocol}//${this.ip}:${this.port}/api/`
  }

  get busNoticeUrl () {
    return `${location.protocol}//${this.ip}:${this.port}/`
  }

  loading = false
  showModal = false
  queryData: PagerQueryBean = _.cloneDeep(defaultQuery)
  pageData: PageListData<ApplicationProfile>={
    items: [],
    total: 0,
    pageSize: 10
  }

  formData: ApplicationProfile={}

  // 手动输入的applicationName
  appNameInput?: string = ''

  model = 'new'

  tableConfig=tableConfig

  rules={
    profile: [
      { required: true, type: 'string', message: '版本名称不能为空', trigger: 'blur' }
    ],
    branch: [
      { required: true, type: 'string', message: '应用分支不能为空', trigger: 'blur' }
    ]
  }

  created () {
    this.queryData.applicationCode = this.curApplication.applicationCode
    this.ip = this.$route.params.ip
    this.port = this.$route.params.port

    this.doPullDataList()
  }

  @Watch('curApplication')
  watchCurApplication (newValue: ConfigApplication) {
    this.queryData.applicationCode = newValue.applicationCode
    this.ip = this.$route.params.ip
    this.port = this.$route.params.port

    this.doPullDataList()
  }

  /// 返回上级
  backApplicationEdit () {
    this.$router.back()
  }

  /**
  * 从服务器拉起数据
  */
  onPullDataListFromServer (pagerQueryBean: PagerQueryBean): Promise<PageDataApiResult<ApplicationProfile>> {
    return ApplicationProfileApi.doPullDataList(pagerQueryBean, this.baseUrl)
  }

  /// 拉取表格数据
  doPullDataList () {
    ApplicationProfileApi.doPullDataList(this.queryData, this.baseUrl).then((res) => {
      this.pageData.items = res.data?.items || []
    })
  }

  // 新增应用
  addProfile () {
    this.model = 'new'
    this.formData = {
      applicationName: this.curApplication.applicationName,
      applicationCode: this.curApplication.applicationCode
    }
    this.showModal = true
  }

  /// 编辑项目信息
  editInfo (val: any) {
    this.model = 'edit'
    this.formData = _.cloneDeep(val)
    this.showModal = true
  }

  /// 编辑项目配置明细
  editConfigDetail (val: any) {
    updateCurAppProfile(val)
    this.$router.push({
      name: 'config-detail',
      params: {
        port: this.port,
        ip: this.ip
      }
    })
  }

  /// 保存版本
  saveProfile () {
    ApplicationProfileApi.saveItem(this.formData, this.baseUrl).then((res) => {
      this.$Notice.success({
        title: '成功',
        desc: this.model === 'new' ? '新增成功' : '保存成功'
      })
      this.showModal = false
      this.doPullDataList()
    }).catch((err) => {
      this.$Notice.error({
        title: '错误',
        desc: err.message
      })
      this.showModal = false
    })
  }

  /**
   * 根据id删除数据项
   * @param id
   */
  deleteItemById (id: number) {
    this.$Modal.confirm({
      title: '确认',
      content: '<p>确认要删除该数据吗？</p>',
      onOk: () => {
        ApplicationProfileApi.deleteItemByIds([id], this.baseUrl).then((res) => {
          this.$Notice.success({
            title: '成功',
            desc: '删除成功'
          })
          this.doPullDataList()
        }).catch((err) => {
          this.$Notice.error({
            title: '错误',
            desc: err.message
          })
        })
      }
    })
  }

  /**
  * 表格刷新按钮点击事件
  * @param querybean
  */
  onTablePanelRefresh () {
    this.doPullDataList()
  }
}
